@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800&display=swap');

:root {
  --font-family: 'Poppins', sans-serif;
  /* --gradient-text: linear-gradient(89.97deg, #AE67FA 1.84%, #F49867 102.67%); */
  --gradient-text: linear-gradient(89.97deg, #40BFFF 1.84%, #BFEAFF 102.67%);
  /* --gradient-bar: linear-gradient(103.22deg, #AE67FA -13.86%, #F49867 99.55%); */
  --gradient-bar: linear-gradient(103.22deg, #40BFFF -13.86%, #BFEAFF 99.55%);
  --color-bg: #f5f5f5;
  --color-primary: #101820;
  --color-secondary: #707479;
  --color-accent: #b7babc;
  /* --color-50: #E2F3F7;
  --color-100: #B6E0EA;
  --color-200: #86CCDD;
  --color-500: #0D98BA;
  --color-800: #4d4d4d;
  --color-900: #000000;
  --color-A100: #BFEAFF;
  --color-A700: #DC5F00; */
  --color-white: #FFFFFF;
  --color-black: #000000;
  --color-darker-white: #eaedf2;
  --color-facebook: #3a559f;
  --color-google: #dd4b39;
  --color-lighter-black: #262626;
  --color-ultramarine-blue: rgb(121, 150, 245);
  --color-success: #24973f;
  --color-error: #ff0000;
  --color-contrast: #BA2F0D;
  --color-orange: #FFA500;
  --color-yellow: #FFFF00;
}

.loader {
  position: absolute;
  left: 50%;
  top: 40%;
}

/* resume builder css ---------- */
@import './assets/css/animate.css';
@import './assets/css/fonts.css';

.rj-body {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.rj-body::-webkit-scrollbar {
  display: none;
}

/* html, */
.rj-body {
  height: 100%;
  color: #2d3748;
  background-color: #f5f5f5;
  font-size: 14px;
  font-family: 'Montserrat', sans-serif;
}

.rj-ul:not(.list-none) .rj-li:before {
  content: '●';
  padding-right: 6px;
}

.rj-input[type='range']::-moz-range-thumb {
  width: 20px;
  height: 20px;
  appearance: none;
  cursor: ew-resize;
  background: #fff;
  border: none;
  box-shadow: -405px 0 0 400px #605e5c;
  border-radius: 50%;
}

.rj-input[type='range']::-webkit-slider-thumb {
  width: 20px;
  height: 20px;
  appearance: none;
  cursor: ew-resize;
  background: #fff;
  border: none;
  box-shadow: -405px 0 0 400px #605e5c;
  border-radius: 50%;
}

.rj-centered {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media screen {

  .rj-input[type='number']::-webkit-inner-spin-button,
  .rj-input[type='number']::-webkit-outer-spin-button {
    appearance: none;
    margin: 0;
  }

  .rj-input[type='number'] {
    -moz-appearance: textfield;
  }

  .rj-input:focus {
    outline: none !important;
  }

  .rj-button:focus {
    outline: none !important;
  }

  .rj-input:checked+i.material-icons {
    display: block;
  }

  #sidebar {
    top: 0;
    left: 0;
    bottom: 0;
  }

  #tabs {
    scroll-behavior: smooth;
  }

  #page {
    width: 18cm;
    min-height: 26.7cm;
    background-color: white;
  }

  .resume-view-main-div {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1rem;
  }

  #printPage {
    display: none;
  }

  #pageController {
    bottom: 25px;
  }

  /* #pageController > div {
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2);
  } */
}

@page {
  size: A4;
  margin: 0;
}

@media print {

  html,
  body,
  body * {
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
    visibility: visible;
  }

  #printPage,
  #printPage * {
    visibility: visible;
    page-break-inside: avoid;
  }

  #printPage {
    width: 21cm;
    height: 29.7cm;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }
}